import * as React from 'react';
import Home from './components/src/home/index'


export default function App() {
  return (
    <>
    
    <Home/>
    </>
  );
}
